
import { Vue, Component } from 'vue-property-decorator';
import HomepageList from '@/components/homepage/list.vue';

@Component({
  name: 'MakerspaceIndex',
  components: {
    HomepageList,
  },
})
export default class extends Vue {
}
